// libraries
import React from 'react';
import 'normalize.css';
import { graphql } from 'gatsby';
// components
import { SEO } from 'components/SEO';
import { GlobalGridVars } from 'components/UI/Grid';
import { GlobalStyles } from 'components/UI/GlobalStyles';
import { Layout } from 'components/Layout';
// helpers
import { renderBlock } from 'helpers/renderBlock';

const PageADW = ({ data }) => {
    const { layouts } = data.page.layouts;
    const { qrBlockAdw } = data.page.qrBlockAdw;

    return (
        <Layout data={data.page}>
            <GlobalStyles />
            <GlobalGridVars />
            {layouts
                ? layouts.map((item, index) => (
                      <React.Fragment key={item.fieldGroupName + index}>
                          {renderBlock(item, false, qrBlockAdw)}
                      </React.Fragment>
                  ))
                : null}
        </Layout>
    );
};
export default PageADW;

export const Head = ({ data }) => {
    return <SEO data={{ ...data.page.seo, ...data.page.template }} />;
};

export const query = graphql`
    query ($id: String!) {
        page: wpPage(id: { eq: $id }) {
            template {
                templateName
            }
            databaseId
            title
            link
            seo {
                canonical
                metaDesc
                metaRobotsNofollow
                metaRobotsNoindex
                title
            }
            qrBlockAdw {
                qrBlockAdw {
                    titleQR: title
                    subtitleQR: subtitle
                    linkQR: link
                    imageQR: image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
                            }
                        }
                    }
                }
            }
            layouts {
                layouts {
                    ... on WpPage_Layouts_Layouts_Hero {
                        fieldGroupName
                        ...Hero
                    }
                    ... on WpPage_Layouts_Layouts_Recompensas {
                        fieldGroupName
                        ...Recompensas
                    }
                    ... on WpPage_Layouts_Layouts_WellBeing {
                        fieldGroupName
                        ...WellBeing
                    }
                    ... on WpPage_Layouts_Layouts_Steps {
                        fieldGroupName
                        ...Steps
                    }
                    ... on WpPage_Layouts_Layouts_Pay {
                        fieldGroupName
                        ...Pay
                    }
                    ... on WpPage_Layouts_Layouts_Security {
                        fieldGroupName
                        ...Security
                    }
                    ... on WpPage_Layouts_Layouts_Faq {
                        fieldGroupName
                        ...Faq
                    }
                    ... on WpPage_Layouts_Layouts_Aboutus {
                        fieldGroupName
                        ...AboutUs
                    }
                    ... on WpPage_Layouts_Layouts_Rewards {
                        fieldGroupName
                        ...Rewards
                    }
                    ... on WpPage_Layouts_Layouts_Income {
                        fieldGroupName
                        ...Income
                    }
                    ... on WpPage_Layouts_Layouts_Company {
                        fieldGroupName
                        ...Company
                    }
                    ... on WpPage_Layouts_Layouts_WriteUs {
                        fieldGroupName
                        ...WriteUs
                    }
                    ... on WpPage_Layouts_Layouts_HeroAyuda {
                        fieldGroupName
                        ...HeroAyuda
                    }
                    ... on WpPage_Layouts_Layouts_AyudaBlock {
                        fieldGroupName
                        ...AyudaBlock
                    }
                    ... on WpPage_Layouts_Layouts_Advantages {
                        fieldGroupName
                        ...Advantages
                    }
                    ... on WpPage_Layouts_Layouts_CashOut {
                        fieldGroupName
                        ...CashOut
                    }
                    ... on WpPage_Layouts_Layouts_Payments {
                        fieldGroupName
                        ...Payments
                    }
                    ... on WpPage_Layouts_Layouts_HeroAdw {
                        fieldGroupName
                        ...HeroADW
                    }
                    ... on WpPage_Layouts_Layouts_Tarjeta {
                        fieldGroupName
                        ...Tarjeta
                    }
                }
            }
        }
    }
`;
